import { Dashboard } from "types/Dashboard";
import { DashboardActionType, DashboardReducerActions } from "../useDashboardsReducer/useDashboardsReducer";
import { useEffect, useRef } from "react";
import DashboardService from "pages/Dashboards/services/DashboardService";
import useInfoMessage from "hooks/useInfoMessage";
import useDashboardPermissions from "pages/Dashboards/hooks/useDashboardPermissions";
import debounce from "lodash/debounce";

/**
 * Dashboard Autosave Effect Hook
 * The save action is debounced to avoid multiple save calls
 * Grid layout updates can trigger multiple save calls
 */
const debouncedTimeout = 1500;
const debouncedSaveDashboard = debounce(DashboardService.save, debouncedTimeout);

function useDashboardAutosaveEffect(
  dashboard: Dashboard | null,
  dispatchDashboards: React.Dispatch<DashboardReducerActions>
) {
  const onError = useInfoMessage({ type: "error" });
  const uneditedDashboardRef = useRef<Dashboard | null>(null);
  const previousDashboardCopy = useRef<Dashboard | null>(null);
  const permissions = useDashboardPermissions(dashboard);

  useEffect(() => {
    if (permissions.saveDisabled) {
      return;
    }

    if (uneditedDashboardRef.current?.id !== dashboard?.id) {
      /**
       * On select different dashboard, update selected backup
       */
      uneditedDashboardRef.current = dashboard;
      previousDashboardCopy.current = dashboard;
    } else if (dashboard && uneditedDashboardRef.current && dashboard.id === uneditedDashboardRef.current.id) {
      const uneditedBackup = uneditedDashboardRef.current;
      const latestDashboard = previousDashboardCopy.current!;

      /**
       * If dashboard changes and the selectedDashboard.id is the same, save the dashboard
       */
      (async () => {
        /**
         * Avoid saving dashboard changes if the only change is the global status
         */
        if (latestDashboard.isGlobal !== dashboard.isGlobal) {
          previousDashboardCopy.current = dashboard;
          return;
        }

        try {
          await debouncedSaveDashboard({
            ...dashboard,
            excludedAthleteIds: [],
            tiles: dashboard.tiles.map((tile) => ({ ...tile, widget: { ...tile.widget, excludedAthleteIds: [] } })),
          });

          previousDashboardCopy.current = dashboard;
        } catch (error) {
          /**
           * Revert on Error
           */
          dispatchDashboards({ type: DashboardActionType.REVERT_ONE, payload: uneditedBackup });
          onError(error);
        }
      })();
    }
  }, [dashboard, dispatchDashboards, onError, permissions.saveDisabled]);

  useEffect(
    () => () => {
      /**
       * Ensure to save any changes on unmount */
      debouncedSaveDashboard.flush();
    },
    [dashboard?.id]
  );

  return uneditedDashboardRef;
}

export default useDashboardAutosaveEffect;
